<template>
    <div>
        <jy-dialog type="form" :title="title" :visible.sync="dialogVisible" custom-class="dialog_form" :width="global.dialogWidth">
            <div>
                <el-form :model="diaform" ref="dialogForm" :rules="rules">
                    <el-form-item label="审核结果:" prop="auditRet" :label-width="formLabelWidth">
                        <el-radio-group v-model="diaform.auditRet">
                            <el-radio label="1">同意</el-radio>
                            <el-radio label="0">不同意</el-radio>
                          </el-radio-group>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="save">确 定</el-button>
            </div>
        </jy-dialog>
    </div>
</template>
<script>
export default {
    data() {
        return {
            // 对话框类型
            title: '业务请求审核',

            diaform: {
                auditRet: '',
            },
            dialogVisible: false,
            formLabelWidth: '120px',

            rules: {
                auditRet: [
                    {
                        required: true,
                        message: '请选择审核结果',
                        trigger: 'change'
                    }
                ]
            }
        }
    },
    watch: {
        dialogVisible(newview) {
            if (!newview) {
                this.resetForm('dialogForm')
            }
        }
    },
    methods: {
        // 打开对话框
        init(option) {
            this.dialogVisible = true
            this.id = option.id
        },
        // 重置
        resetForm(formName) {
            this.$refs[formName].resetFields()
        },

        cancel() {
            this.dialogVisible = false
            console.log('cancel')
        },
        save() {
            this.$refs.dialogForm.validate(valid => {
                if (valid) {
                    let url = '/stat/BusinessReq/auditBusinessReq'
                    let option = {
                        id:this.id,
                        ...this.diaform,
                    }
                    this.$http.post(url, option).then(res => {
                        this.$message({
                            message: res.msg,
                            type: 'success'
                        })
                        this.dialogVisible = false
                        this.$emit('updateList')
                    })
                } else {
                    return false
                }
            })
        }
    }
}
</script>
<style lang="scss" scoped>
</style>