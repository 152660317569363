<template>
    <div>
        <jy-query ref="form" :model="form">
            <jy-query-item label="线路:" prop="routeId" label-width="70px">
                <!-- <el-input placeholder="请选择" v-model="form.routeName" @focus="xlselectInstitutions"></el-input> -->
                <el-select v-model="form.routeId" placeholder="请选择线路">
                    <el-option v-for="item in routeOptions" :key="item.routeId" :label="item.name" :value="item.routeId">
                    </el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item prop="vehicleNo" label="车牌号:">
                <el-input placeholder="请输入" v-model="form.vehicleNo"></el-input>
            </jy-query-item>
            <jy-query-item prop="requestType" label="请求类型:">
                <el-select v-model="form.requestType" placeholder="请选择">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item prop="isAudit" label="审核状态:">
                <el-select v-model="form.isAudit" placeholder="请选择">
                    <el-option v-for="item in shoptions" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="查询日期:" prop="time" :span="1.5">
                <el-date-picker v-model="form.time" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions" :unlink-panels="true"></el-date-picker>
            </jy-query-item>
            <template slot="rightCol">
                <el-button type="primary" @click="oncheck" v-if="btnexist('list')">查询</el-button>
                <el-button type="primary" @click="resetForm('form')" v-if="btnexist('reset')">重置</el-button>
                <el-row>
                   <el-button type="primary" @click="exportList" v-if="btnexist('export')">导出</el-button>
                </el-row>
            </template>
        </jy-query>
        <jy-table :data="dataList" v-loading="loading">
            <jy-table-column type="index" label="序号" width="60"></jy-table-column>
            <jy-table-column prop="routeNo" label="线路编号"></jy-table-column>
            <jy-table-column prop="routeName" label="线路名称"></jy-table-column>
            <jy-table-column prop="orgName" label="所属机构"></jy-table-column>
            <jy-table-column prop="vehicleNo" label="车牌号"></jy-table-column>
            <jy-table-column prop="deviceNo" label="设备ID"></jy-table-column>
            <jy-table-column prop="driverName" label="司机姓名"></jy-table-column>
            <jy-table-column prop="workerId" label="司机工号"></jy-table-column>
            <jy-table-column prop="requestType" label="请求类型"></jy-table-column>
            <jy-table-column prop="time" label="请求时间" min-width="160"></jy-table-column>
            <jy-table-column prop="isAuditName" label="审核状态"></jy-table-column>
            <jy-table-column prop="auditState" label="审核结果"></jy-table-column>
            <jy-table-column prop="auditUser" label="审核人"></jy-table-column>
            <jy-operate v-bind:list="operateList" width="100"></jy-operate>
        </jy-table>
        <jy-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageIndex" :page-size="pageSize" :total="total"></jy-pagination>
        <xlinstitutions-tree ref="xlinstitutions" @addInstitutions="xladdInstitutions" url="/waybillOperate/getTree" title="选择线路" :defaultProps="props"></xlinstitutions-tree>
        <audit ref="audit" @updateList="getList"></audit>
    </div>
</template>
<script>
import xlinstitutionsTree from '@/components/pages/admin/common/dialog-tree/institutions_tree'
import audit from '@/components/pages/admin/detailData/businessRequestsDetail/audit'
import {btnMixins} from '@/common/js/button.mixin'
export default {
    data() {
        return {
            pickerOptions: {
                // disabledDate(time) {
                //     return time.getTime() > Date.now() - 8.64e7
                // },
            },

            props: {
                children: 'children',
                label: 'text',
                value: 'id',
            },
            form: {
                routeName: '',
                routeId: '',
                vehicleNo: '',
                requestType: '',
                isAudit:'',
                time: [],
            },
            pageSize: 10,
            total: 0,
            pageIndex: 1,
            // 列表列数据
            dataList: [],
            //  操作
            operateList: [],

            options: [
                {
                    value: '',
                    label: '全部',
                },
                {
                    value: '1',
                    label: '请求排班',
                },
                {
                    value: '2',
                    label: '请求交班',
                },
                {
                    value: '3',
                    label: '请求加油',
                },
                {
                    value: '4',
                    label: '请求加气',
                },
                {
                    value: '5',
                    label: '请求充电',
                },
                {
                    value: '6',
                    label: '退出运营',
                },
                {
                    value: '7',
                    label: '手动开始',
                },
                {
                    value: '8',
                    label: '手动结束',
                },
                {
                    value: '9',
                    label: '请求包车',
                },
                {
                    value: '10',
                    label: '请求维修',
                },
                {
                    value: '11',
                    label: '其他请求',
                },
                {
                    value: '12',
                    label: '进入运营',
                },
                {
                    value: '13',
                    label: '请求对讲',
                },
            ],
            shoptions:[
                {
                    value: '',
                    label: '全部',
                },
                {
                    value: '0',
                    label: '已审核',
                },
                {
                    value: '1',
                    label: '未审核',
                },
            ],

            loading:false,

            routeOptions:[],

            btnRoute:'/admin/detailData/businessRequestsDetail',
        }
    },
    mixins:[btnMixins],
    components: {
        xlinstitutionsTree,
        audit,
    },
    created() {
        this.setOperateList()
        this.getList()
    },
    activated(){
        this.getRouteList()
    },
    methods: {
        getRouteList(){
            let url = '/baseinforoute/queryCurrentAuthorityRoute'
            this.$http.post(url).then(({detail})=>{
                this.routeOptions = detail
            })
        },
        setOperateList() {
            let l = [
                {
                    name: '审核',
                    icon: 'el-icon-edit-outline',
                    fun: this.toAudit,
                    isShow:(row)=>{
                        return row.isAudit == '1'
                    }
                },
            ]
            this.operateList = l
        },
        getList() {
            let option = {
                ...this.form,
                pageIndex: this.pageIndex,
                pageSize: this.pageSize,
            }
            if (option.time != null && option.time.length) {
                option.startDate = option.time[0]
                option.endDate = option.time[1]
            } else {
                option.startDate = ''
                option.endDate = ''
            }
            let url = '/stat/BusinessReq/queryPage'
            this.loading = true
            this.$http.post(url, option).then(res => {
                this.loading = false
                this.dataList = res.detail.list
                this.total = res.detail.total
            },()=>{
                this.loading = false
            }
)
        },
        // 查询表格
        oncheck() {
            this.pageIndex = 1
            this.getList()
            console.log('更新')
        },
        // 重置
        resetForm(formName) {
            this.$refs[formName].resetFields()
            this.form.routeId = ''
            this.oncheck()
        },
        // 选择线路树
        xlselectInstitutions() {
            this.$refs.xlinstitutions.init()
        },
        // 上级线路机构查询
        xladdInstitutions(data) {
            // console.log(data)
           this.form.routeId = data.id
            this.form.routeName = data.text
        },
        // 分页
        handleSizeChange(val) {
            this.pageSize = val
            this.getList()
        },
        handleCurrentChange(val) {
            this.pageIndex = val
            this.getList()
        },
        toAudit(row){
            this.$refs.audit.init(row)
        },
        //导出
        exportList() {
            let url = '/stat/BusinessReq/export'
            let option = {
                ...this.form,
            }
            if (option.time != null && option.time.length) {
                option.startDate = option.time[0]
                option.endDate = option.time[1]
            } else {
                option.startDate = ''
                option.endDate = ''
            }
            this.$http
                .post(url, option, { type: 'format', isExcel: true })
                .then(res => {
                    this.$util.funDownload(res.data, '业务请求明细')
                })
        },
    },
}
</script>
